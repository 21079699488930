@font-face {
    font-family: monopoly;
    src: url('monopoly.ttf');
}

@font-face {
    font-family: monopolyDouble;
    src: url('monopoly_double.ttf');
}

@font-face {
    font-family: monopolyBold;
    src: url('monopoly_bold.ttf');
}
  